<template>
  <b-modal
    id="modal-save-filter"
    centered
    title="Save Filter"
    size="md"
    :hide-footer="true"
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Filter Name -->
        <validation-provider
          #default="validationContext"
          name="Filter Name"
          rules="required"
        >
          <b-form-group label="Filter Name" label-for="filter">
            <b-form-input
              id="filter"
              v-model="form.name"
              autofocus
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Filter Criteria -->
        <validation-provider
          #default="validationContext"
          name="Criteria"
          rules="required"
        >
          <b-form-group label="Criteria" label-for="criteria">
            <b-form-tags
              id="criteria"
              v-model="form.criteria"
              plaintext
              placeholder=""
              :state="getValidationState(validationContext)"
              :disabled="true"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Is Filter Private -->
        <validation-provider
          #default="validationContext"
          name="Filter Name"
          rules="required"
        >
          <b-form-group label-for="private">
            <b-form-checkbox
              id="private"
              v-model="form.private"
              autofocus
              :state="getValidationState(validationContext)"
            >
              Private
              <b-badge
                v-b-tooltip.hover.top="
                  'Private filters are visible only to you.'
                "
                variant="light-primary"
              >
                ?
              </b-badge>
            </b-form-checkbox>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2 justify-content-between">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hideAddFilterModal()"
          >
            Cancel
          </b-button>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              type="submit"
              @click="hideModal = false"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              @click="hideModal = true"
            >
              Save & Close
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BForm,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BBadge,
    BFormTags,
    VBTooltip,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BModal,
      BForm,
      BFormInvalidFeedback,
      BFormInput,
      BFormGroup,
      BButton,
      BFormCheckbox,
      BBadge,
      BFormTags,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    props: {
      criteria: { type: Array, default: () => [] },
      associatedEntity: { type: String, default: () => '' },
    },
    data() {
      return {
        form: this.getDefaultForm(),
        hideModal: true,
      }
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      hideAddFilterModal() {
        this.resetForm()
        this.$nextTick(() => {
          this.$bvModal.hide('modal-save-filter')
        })
      },
      onSubmit() {
        const action = this.$store.dispatch('filter/addFilter', this.form)
        action.then(() => {
          if (this.hideModal) {
            this.$bvModal.hide('modal-save-filter')
            this.resetForm()
          }
        })
      },
      resetForm() {
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
      },
      getDefaultForm() {
        return {
          name: '',
          private: true,
          criteria: this.criteria,
          associatedEntity: this.associatedEntity,
        }
      },
    },
  }
</script>
