<template>
  <div class="task-list-pagination">
    <div class="per-page-dropdown">
      <span>Show</span>
      <BFormSelect
        :options="perPageOptions"
        @input="$emit('update:per-page', $event)"
        :value="perPage"
      ></BFormSelect>
      <span>entries</span>
    </div>

    <div class="page">Page {{ value }}</div>

    <div class="pagination">
      <BButton
        v-bind="navBtnProps"
        :disabled="loading"
        v-if="value > 1"
        @click="$emit('input', value - 1)"
      >
        <FeatherIcon icon="ArrowLeftIcon" />
        <span>Previous</span>
      </BButton>
      <BButton
        v-bind="navBtnProps"
        :disabled="loading"
        @click="$emit('input', value + 1)"
        v-if="hasNextPage"
      >
        <span>Next</span>
        <FeatherIcon icon="ArrowRightIcon" />
      </BButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import { BButton, BFormSelect } from "bootstrap-vue"

export default defineComponent({
  name: "TaskListPagination",
  components: {
    BButton,
    BFormSelect,
  },
  props: {
    value: Number,
    perPageOptions: {
      type: Array,
      default: () => [],
    },
    perPage: Number,
    hasNextPage: Boolean,
    loading: Boolean,
  },
  setup() {
    return {
      navBtnProps: {
        variant: "primary",
        size: "sm",
      },
    }
  },
})
</script>

<style lang="scss">
.task-list-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .pagination,
  .pagination button,
  .per-page-dropdown {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .page {
    margin: 0 30px;
  }
}
</style>
