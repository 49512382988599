<template>
  <div>
    <b-modal
      id="modal-filter-tasks-by-date"
      centered
      title="Filter Tasks By Date"
      size="md"
      :hide-footer="true"
    >
      <div class="d-flex justify-content-between pb-2">
        <div>
          <label>Start Date</label>
          <flat-pickr
            v-model="startDate"
            :config="{ dateFormat: 'Y-m-d' }"
            class="form-control"
          />
        </div>
        <div>
          <label>End Date</label>
          <flat-pickr
            v-model="endDate"
            :config="{ dateFormat: 'Y-m-d' }"
            class="form-control"
          />
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <b-button class="mr-1"
@click="closeModal"
> Cancel </b-button>
        <b-button class="mr-1"
@click="onSubmit"
> Filter </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { BModal, BButton } from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
  import { nextTick } from '@vue/composition-api'
  import { formatDate } from '@/utils/date-utils'

  export default {
    components: {
      BModal,
      flatPickr,
      BButton,
    },
    model: {
      prop: 'dateRange',
      event: 'update:date-range',
    },
    props: {
      dateRange: {
        type: String,
        required: true,
        default: `${new Date(Date.now())} to ${new Date(Date.now() + 12096e5)}`,
      },
      isEditing: {
        type: Boolean,
        required: false,
        default: () => false,
        event: 'update: is-editing',
      },
    },
    data() {
      return {
        startDate: formatDate(new Date(Date.now())),
        endDate: formatDate(new Date(Date.now() + 12096e5)),
      }
    },
    watch: {
      dateRange(newValue) {
        ;[this.startDate, this.endDate] = newValue.split(' to ')
      },
    },
    methods: {
      formatDate,
      onSubmit() {
        nextTick(() => {
          if (this.startDate && this.endDate) {
            this.$emit(
              'update:date-range',
              `${this.startDate} to ${this.endDate}`
            )
            // this is used to not let the task-list change the date range
            // this.$emit('update:is-editing', true)
            // this.$emit('reset-is-editing-to-false')
          }
        })
        this.closeModal()
      },
      closeModal() {
        document.getElementById('modal-filter-tasks-by-date').click()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
