<template>
  <div class="task-list">
    <task-add
      :is-sidebar-active.sync="isAddNewTaskSidebarActive"
      :is-readonly.sync="isAddNewTaskSidebarInDisplayMode"
      :value.sync="activeRecord"
      :edit-tab="editRecord"
      @regenerate-tasks-to-display="
        postTaskUpdateActions(activeRecord, $event, activeRecordIdx)
      "
      @reset-is-editing-to-false="resetIsEditingValue"
      :init="init"
    />

    <!-- filter tasks by date modal -->
    <filter-tasks-by-date-modal
      :date-range.sync="dateRange"
      :is-editing.sync="isEditing"
      @reset-is-editing-to-false="resetIsEditingValue"
      @update:date-range="onDateSelect($event)"
    />

    <!-- Table Container Card -->
    <!--      no-body-->
    <div class="mb-0 container-card">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <entity-filter
                entity="task"
                entity-plural-slug="tasks"
                :entity-field-suggestions="suggestions"
                :search-query.sync="searchQuery"
                :selected-queries.sync="selectedQueries"
                :is-editing.sync="isEditing"
                @reset-is-editing-to-false="resetIsEditingValue"
                @onClearFilter="onClearFilter"
                :init="init"
              />
              <transition name="slide-fade">
                <div v-if="selectedItems.length !== 0">
                  <!-- More Actions On Selected Items Dropdown -->
                  <b-dropdown
                    class="ml-1"
                    toggle-class="text-decoration-none"
                    text="Saved Filters"
                    variant="outline-secondary"
                    no-caret
                    right
                  >
                    <!-- Button Content -->
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" />
                    </template>

                    <template>
                      <!-- Mark Selected Items As NotStarted -->
                      <b-dropdown-item
                        @click="markSelectedItemsAsChosenStatus('NotStarted')"
                      >
                        <span class="align-middle"
                          >Mark as
                          <b-badge
                            pill
                            :variant="`light-${resolveTaskStatusVariant(
                              'NotStarted'
                            )}`"
                            class="text-capitalize"
                          >
                            {{ splitCamelCaseWords("NotStarted") }}
                          </b-badge>
                        </span>
                      </b-dropdown-item>

                      <!-- Mark Selected Items As InProgress -->
                      <b-dropdown-item
                        @click="markSelectedItemsAsChosenStatus('InProgress')"
                      >
                        <span class="align-middle"
                          >Mark as
                          <b-badge
                            pill
                            :variant="`light-${resolveTaskStatusVariant(
                              'InProgress'
                            )}`"
                            class="text-capitalize"
                          >
                            {{ splitCamelCaseWords("InProgress") }}
                          </b-badge>
                        </span>
                      </b-dropdown-item>

                      <!-- Mark Selected Items As Completed -->
                      <b-dropdown-item
                        @click="markSelectedItemsAsChosenStatus('Complete')"
                      >
                        <span class="align-middle"
                          >Mark as
                          <b-badge
                            pill
                            :variant="`light-${resolveTaskStatusVariant(
                              'Complete'
                            )}`"
                            class="text-capitalize"
                          >
                            {{ splitCamelCaseWords("Complete") }}
                          </b-badge>
                        </span>
                      </b-dropdown-item>
                      <!-- Export Selected Items -->
                      <b-dropdown-item
                        @click="markSelectedItemsAsChosenStatus('Exported')"
                      >
                        <span class="align-middle">
                          <b-badge
                            pill
                            variant="warning"
                            class="text-capitalize"
                          >
                            {{ splitCamelCaseWords("Export") }}
                          </b-badge>
                        </span>
                      </b-dropdown-item>
                      <!-- Delete Selected Items -->
                      <b-dropdown-item @click="deleteSelectedRecords">
                        <span class="align-middle">
                          <b-badge
                            pill
                            variant="danger"
                            class="text-capitalize"
                          >
                            {{ splitCamelCaseWords("Delete") }}
                          </b-badge>
                        </span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </div>
              </transition>
              <b-button
                class="ml-1"
                variant="primary"
                @click="editRecord(null)"
              >
                <span class="text-nowrap">Add Task</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="task-list-table">
        <b-table
          id="taskListTable"
          ref="refTaskListTable"
          class="position-relative table"
          :items="tasksToDisplay"
          :hover="true"
          responsive
          :fields="tableColumns"
          primary-key="init_handle"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :no-local-sorting="true"
          :busy="loading"
          show-empty
          :empty-text="`No matching records found between ${formatDateForUser(
            from
          )} to ${formatDateForUser(to)}`"
          @row-clicked="displayRecord"
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-1 align-items-center"
              style="min-height: 60vh"
            >
              <b-spinner
                style="width: 4rem; height: 4rem"
                type="grow"
                variant="primary"
                label="Loading..."
              />
            </div>
          </template>

          <!-- Header: Select -->
          <template #head(select)="[]">
            <div class="d-flex">
              <b-form-checkbox
                style="text-align: center !important; margin-left: 5px"
                :checked="
                  tasksToDisplay.length !== 0 &&
                  tasksToDisplay.length === selectedItems.length
                "
                @change="selectOrUnselectAllRows($event)"
              />
            </div>
          </template>

          <!-- Column: Select -->
          <template #cell(select)="data">
            <template>
              <b-form-checkbox
                style="text-align: center !important; margin-left: 5px"
                :checked="
                  !!selectedItems.find((e) => e.handle === data.item.handle)
                "
                @change="selectOrUnselectRow($event, data.item)"
              />
            </template>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <span>{{ data.item.name }}</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="action-buttons">
              <!-- Edit Button -->
              <!-- <b-button
              :id="`row-${data.item.handle}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />

              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`row-${data.item.handle}-edit-icon`"
              />
            </b-button> -->

              <!-- Restore Button -->
              <!-- <b-button
              v-if="data.item.deleted"
              :id="`row-${data.item.handle}-delete-icon`"
              variant="flat-info"
              class="btn-icon rounded-circle"
              @click="restoreRecord(data.item)"
            >
              <feather-icon
                icon="ShareIcon"
                size="16"
              />
              <b-tooltip
                title="Restore"
                class="cursor-pointer"
                :target="`row-${data.item.handle}-delete-icon`"
              />
            </b-button> -->

              <!-- Delete Button -->
              <!-- <b-button
              v-else
              :id="`row-${data.item.handle}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
              <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`row-${data.item.handle}-delete-icon`"
              />
            </b-button> -->

              <!-- Options DropDown -->
              <b-dropdown variant="link" no-caret right class="edit-icon">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editRecord(data.item, data.index)">
                  <span class="align-middle text-primary">
                    <feather-icon icon="EditIcon" size="16" />
                    Edit
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.item.deleted"
                  @click="deleteOrRestoreRecord(data.item, data.index)"
                >
                  <span class="align-middle text-info">
                    <feather-icon icon="ShareIcon" size="16" />
                    Restore
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-else
                  @click="deleteOrRestoreRecord(data.item, data.index)"
                >
                  <span class="align-middle text-danger">
                    <feather-icon icon="TrashIcon" size="16" />
                    Delete
                  </span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <!-- Mark As 'chosen status' -->
                <b-dropdown-item
                  v-for="markAsOption in markAsOptions.filter(
                    (option) => data.item.status !== option
                  )"
                  :key="markAsOption"
                  @click="
                    updateTask(data.item, data.index, { status: markAsOption })
                  "
                >
                  <span class="align-middle"
                    >Mark as
                    <b-badge
                      pill
                      :variant="`light-${resolveTaskStatusVariant(
                        markAsOption
                      )}`"
                      class="text-capitalize"
                    >
                      {{ splitCamelCaseWords(markAsOption) }}
                    </b-badge>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

          <!-- Column: Last Updated -->
          <template #cell(dueDate)="data">
            <div>
              {{ data.item.dueDate.format(TASK_DUE_DATE_DISPLAY_FORMAT) }}
            </div>
          </template>

          <!-- Column: Last Updated -->
          <template #cell(updated_at)="data">
            <div v-if="data.item.updated_at">
              {{ formattedDueDate(data.item.updated_at) }}
            </div>
            <div v-else>--</div>
          </template>

          <!-- Column: Assignee -->
          <template #cell(userId)="data">
            <span>{{
              data.item.assignee ? data.item.assignee.name : "--"
            }}</span>
          </template>

          <!-- Column: Process -->
          <template #cell(processId)="data">
            <div>
              {{ data.item.processHandle }}
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div
              class="d-flex flex-wrap status-badges-container"
              style="max-width: 150px"
            >
              <b-badge
                pill
                :variant="`light-${resolveTaskStatusVariant(data.item.status)}`"
                class="text-capitalize"
                style="margin-top: 4px"
              >
                {{ splitCamelCaseWords(data.item.status) }}
              </b-badge>
              <!-- v-if="moment(data.item.dueDate).diff(Date.now(), 'days') < 0 && data.item.status !== 'Complete'" -->
              <b-badge
                v-if="data.item.is_late"
                pill
                variant="danger"
                class="text-capitalize"
                style="margin-left: 5px; margin-top: 4px"
              >
                Late
              </b-badge>
              <b-badge
                v-if="data.item.deleted"
                pill
                variant="secondary"
                class="text-capitalize"
                style="margin-left: 5px; margin-top: 4px"
              >
                Deleted
              </b-badge>
            </div>
          </template>

          <template #bottom-row>
            <b-th colspan="99">
              <TaskListPagination
                :loading="paginationLoading"
                :value="currentPage"
                @input="pageChange"
                :per-page-options="perPageOptions"
                :per-page.sync="perPage"
                :has-next-page="hasNextPage"
              />
            </b-th>
          </template>

          <!-- <template
            v-if="tasksToDisplay.length > 0 && !showPagination"
            slot="bottom-row"
          >
            <b-th colspan="6">
              <b-button
                variant="outline-primary"
                class="mt-1 mb-1"
                style="width: 100%"
                @click="loadTwoMoreWeeksClicked()"
              >
                Load More Tasks
              </b-button>
            </b-th>
            <b-th colspan="4">
              <v-select
                v-model="loadTasksSelectedOption"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="loadTasksOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                :disabled="false"
                class="wrapped-li"
                input-id="loadTasksOption"
                @input="onLoadOptionSelect"
              />
            </b-th>
          </template> -->
        </b-table>
      </div>

      <div v-if="showPagination" class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of
              {{ paginationMeta.of }} entries</span
            >
          </b-col>

          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="
                currentRouteName === 'my-process-list'
                  ? currentUserTasks.totalCount
                  : tasks.totalCount
              "
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 vue-select-up"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TaskListPagination from "./TaskListPagination.vue"
import {
  generateFutureTasks,
  TASK_DUE_DATE_DISPLAY_FORMAT,
  isValidTaskFilter,
  removeExtraFieldsFromTask,
  formatPersistedTask,
  formatProcessesForTaskGeneration,
} from "../../utils/task-helper"
import { areArraysEqual } from "../../utils/object-util"
import {
  BRow,
  BCol,
  BButton,
  BTable,
  // BTooltip,
  BSpinner,
  BCardText,
  VBTooltip,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckbox,
  BPagination,
  BTh,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { debounce } from "vue-debounce"
import { mapState, mapActions, mapMutations } from "vuex"
// eslint-disable-next-line import/extensions
import { formatDate, formatDateForUser } from "@/utils/date-utils.js"
import { DAYS_BETWEEN_DEFAULT_INTERVAL } from "@/utils/constants"
import { selectPosition } from "@core/mixins/ui/select"
import EntityFilter from "@/views/shared/Filter.vue"
import { TASK_ENTITY_FIELD_SUGGESTIONS } from "@/utils/entity-filter"
import FilterTasksByDateModal from "@/views/task/FilterTasksByDateModal.vue"
import vSelect from "vue-select"
import moment from "moment"
import TaskAdd from "./TaskAdd.vue"
import _ from "lodash"
import Vue from "vue"

export default {
  components: {
    TaskListPagination,
    vSelect,
    // custom components
    TaskAdd,
    EntityFilter,
    FilterTasksByDateModal,

    // Bootstrap components
    BRow,
    BCol,
    BButton,
    BTable,
    // BTooltip,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BPagination,
    BTh,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [selectPosition],
  data() {
    return {
      init: false,
      processesForTasks: [],
      paginationLoading: true,
      loading: true,
      tasksToSkip: {},
      hasNextPage: true,
      oldFilters: [],
      TASK_DUE_DATE_DISPLAY_FORMAT,
      tableColumns: [
        {
          key: "select",
          stickyColumn: true,
          label: "select",
          class: "select-edit",
        },
        {
          key: "processId",
          label: "Process ID",
          class: "my-class-task my-id-class-task",
        },
        {
          key: "name",
          label: "Task Name",
          class: "my-class-task my-name-class-task",
        },
        {
          key: "dueDate",
          label: "Due Date",
          class: "my-class-task my-date-class",
        },
        {
          key: "updated_at",
          label: "Last Updated",
          class: "my-class-task my-date-class",
        },
        {
          key: "userId",
          label: "Assignee",
          class: "my-class-task my-name-class-task",
        },
        {
          key: "status",
          label: "Status",
          class: "my-class-task",
        },
        { key: "actions", class: "task-action-btns" },
      ],
      isAddNewTaskSidebarActive: false,
      isAddNewTaskSidebarInDisplayMode: false,
      activeRecord: null,
      activeRecordIdx: null,
      searchFilter: {
        taskType: null,
      },
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      currentPage: 1,
      dynamicTasks: [],
      lastDynamicTaskEveryPage: [],
      lastDisplayedTaskEveryPage: [],
      dbTasksToSkipPerPage: [],
      searchQuery: [],
      selectedQueries: [],
      sortBy: "dueDate",
      isSortDirDesc: false,
      generatedTasksForAllUsers: [],
      // from: new Date(Date.now()),
      // from: '',
      from: new Date(
        new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 1)
      ),
      to: new Date(
        new Date(Date.now()).setDate(
          new Date(Date.now()).getDate() + DAYS_BETWEEN_DEFAULT_INTERVAL
        )
      ),
      tasksToDisplay: [],
      tasksToDisplayLoading: false,
      maxIterations: 26, // Loads at maximum for one year
      iteration: 0,
      isEditing: false,
      showPagination: false,

      // FOR AUTO SUGGEST
      filteringFunctions: ["!task.deleted && task.status !== 'Complete'"], // used for filtering generated tasks using javascript
      TASK_ENTITY_FIELD_SUGGESTIONS,

      // For Mark as "status" functionality
      markAsOptions: ["NotStarted", "InProgress", "Complete"],

      loadTasksSelectedOption: { label: "Next 2 Weeks", value: 5 },
      loadTasksDays: 5,
      loadTasksOptions: [
        { label: "Next 5 Days", value: 5 },
        { label: "Next 2 Weeks", value: 14 },
        { label: "Next 1 Month", value: 30 },
        { label: "Next 2 Months", value: 60 },
        { label: "Next 6 Months", value: 180 },
        { label: "Next 1 Year", value: 365 },
      ],
      // For selecting table rows
      selectedItems: [],

      // preventing to call the refetch function on page first render
      refetching: false,
    }
  },
  computed: {
    ...mapState("task", [
      "tasks",
      "currentUserTasks",
      // 'fieldValues',
    ]),
    ...mapState("task", { taskIsLoading: "isLoading" }),
    // ...mapState('process', { processIsLoading: 'isLoading' }),
    // ...mapState('user', ['users', 'allUsers']),
    // ...mapState('user', { userIsLoading: 'isLoading' }),
    ...mapState("filter", ["filtersAccessibleToCurrentUser"]),
    paginationMeta() {
      let tasksToPaginate = { nodes: [], totalCount: 0 }
      if (this.currentRouteName === "task-list") tasksToPaginate = this.tasks
      else if (this.currentRouteName === "my-task-list")
        tasksToPaginate = this.currentUserTasks
      return {
        from:
          this.perPage * (this.currentPage - 1) +
          (tasksToPaginate.nodes.length ? 1 : 0),
        to:
          this.perPage * (this.currentPage - 1) + tasksToPaginate.nodes.length,
        of: tasksToPaginate.totalCount,
      }
    },
    queryParams() {
      return {
        taskType: this.searchFilter.taskType,
        q: this.selectedQueries,
        offset: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        sortBy: this.sortBy,
        isDesc: this.isSortDirDesc,
        from: this.from,
        to: this.to,
        paginated: this.showPagination,
      }
    },
    dateRange: {
      get() {
        if (!this.from) {
          return `null to ${formatDate(this.to)}`
        }
        return `${formatDate(this.from)} to ${formatDate(this.to)}`
      },
      set(newValue) {
        const intervalLimits = newValue.split(" to ")
        if (intervalLimits[0] !== "null")
          [this.from, this.to] = intervalLimits.map((e) => new Date(e))
        else {
          this.from = new Date(Date.now())
          this.to = new Date(intervalLimits[1])
        }
      },
    },
    isSelectAllChecked: {
      get() {
        return this.tasksToDisplay.length === this.selectedItems.length
      },
      set(newValue) {
        this.selectOrUnselectAllRows(newValue)
      },
    },
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    perPage() {
      this.generateTasks(true)
    },
    async dateRange(value) {
      const intervalLimits = value.split(" to ")
      // eslint-disable-next-line prefer-const
      let [fromValue, toValue] = intervalLimits.map((e) => new Date(e))
      this.from = fromValue
      this.to = toValue
      this.generateTasks(true)
    },
    selectedQueries(newVal) {
      const getValidFilters = (filters) =>
        filters.filter((f) => isValidTaskFilter(f))

      const string = this.selectedQueries[0]
      if (string) {
        if (string.includes("all")) {
          this.queryParams.sortBy = "updated_at"
          this.sortBy = "updated_at"
          this.isSortDirDesc = true
        } else if (string.includes("active") || string.includes("late")) {
          this.queryParams.sortBy = "dueDate"
          this.sortBy = "dueDate"
          this.isSortDirDesc = false
        } else if (string.includes("complete") || string.includes("delete")) {
          this.queryParams.sortBy = "updated_at"
          this.sortBy = "updated_at"
          this.isSortDirDesc = true
        }
      } else {
        this.queryParams.sortBy = "updated_at"
        this.sortBy = "updated_at"
        this.isSortDirDesc = true
      }

      const validFilters = getValidFilters(newVal)

      if (!areArraysEqual(this.oldFilters, validFilters)) {
        this.fetchTasks(true)
      }

      this.oldFilters = validFilters
    },
    filteringFunctions() {
      // do nothing
    },
    currentRouteName() {
      this.fetchTasks(true, true)
    },
  },
  async created() {
    this.$store.commit("process/RESET_PROCESSES")
    const { q, d } = this.$route.query
    if (q) {
      if (Array.isArray(q)) {
        this.selectedQueries = q
      } else {
        this.selectedQueries = [q]
      }
    } else {
      this.selectedQueries = ["is:active"]
    }

    if (d && d === "org") {
      this.perPage = 10
      this.showPagination = true
      this.suggestions = []
    } else {
      this.suggestions = TASK_ENTITY_FIELD_SUGGESTIONS
    }
  },
  methods: {
    ...mapActions("role", ["getForTaskGeneration"]),
    ...mapActions("process", ["getProcessesForTasks"]),
    ...mapActions("task", ["getTasks", "getCurrentUserTasks"]),
    pageChange(page) {
      const isGoingBack = page < this.currentPage
      this.currentPage = page
      this.generateTasks(false, isGoingBack)
    },
    ...mapActions("filter", ["getFiltersAccessibleToCurrentUser"]),
    refetchData: debounce(async function cb() {
      await this.fetchTasks()
    }, "300ms"),
    onLoadOptionSelect(value) {
      this.loadTasksDays = value
    },
    updateLocalTaskList(idx, task) {
      if (idx === null) return
      this.tasksToDisplay.splice(idx, 1, task)
    },
    async generateTasks(
      reset = false,
      isGoingBack = false,
      removedTaskIndices = []
    ) {
      this.paginationLoading = true

      let resetInternal = reset
      let firstDisplayedTaskBeforeCompletionOrDeletion = null
      if (removedTaskIndices.length) {
        removedTaskIndices.forEach((idx) => {
          const t = this.tasksToDisplay[idx]
          Vue.set(this.tasksToSkip, t.init_handle.split("_")[0], t)
        })

        this.lastDisplayedTaskEveryPage.pop()
        this.lastDynamicTaskEveryPage.pop()
        this.dbTasksToSkipPerPage.pop()

        if (this.currentPage === 1) {
          resetInternal = true
        } else {
          firstDisplayedTaskBeforeCompletionOrDeletion = _.clone(
            this.lastDisplayedTaskEveryPage[
              this.lastDisplayedTaskEveryPage.length - 1
            ]
          )
        }
      }

      if (resetInternal === true) {
        this.dbTasksToSkipPerPage = []
        this.currentPage = 1
        this.tasksToDisplay = []
        this.dynamicTasks = []
        this.lastDynamicTaskEveryPage = []
        this.lastDisplayedTaskEveryPage = []
        this.hasNextPage = true
      }

      if (isGoingBack) {
        this.dbTasksToSkipPerPage.pop()
        this.dbTasksToSkipPerPage.pop()
        this.lastDisplayedTaskEveryPage.pop()
        this.lastDisplayedTaskEveryPage.pop()
      }

      const offset =
        this.dbTasksToSkipPerPage[this.dbTasksToSkipPerPage.length - 1] || 0

      try {
        await this.$store.dispatch("task/getTasks", {
          limit: this.perPage,
          sortBy: "dueDate",
          isDesc: false,
          paginated: true,
          offset,
          totalCount:
            this.currentPage === 1 ? undefined : this.tasks.totalCount,
          q: this.selectedQueries,
          getTasksToSkip: this.currentPage === 1,
          onlyMine: this.$route.name === "my-task-list",
        })

        this.init = true

        if (this.currentPage === 1 && !removedTaskIndices.length) {
          this.tasksToSkip = _.keyBy(
            this.tasks.tasksToSkip,
            (t) => t.init_handle.split("_")[0]
          )
        }
        const { tasks, tasksUsedFromDB, dynamicTasks, hasNextPage } =
          generateFutureTasks(
            this.processesForTasks,
            [...this.tasks.nodes],
            removedTaskIndices.length && !resetInternal
              ? [firstDisplayedTaskBeforeCompletionOrDeletion]
              : this.tasksToDisplay,
            moment(),
            this.perPage,
            this.selectedQueries,
            isGoingBack,
            this.lastDynamicTaskEveryPage,
            this.tasksToSkip
          )

        this.hasNextPage = hasNextPage
        this.tasksToDisplay = tasks
        this.dynamicTasks = dynamicTasks

        if (isGoingBack) {
          this.lastDynamicTaskEveryPage.pop()
          this.lastDisplayedTaskEveryPage.pop()
        } else {
          const t = dynamicTasks[dynamicTasks.length - 1]
          this.lastDynamicTaskEveryPage.push({
            dueDate: t?.dueDate,
            j: t?.j,
          })

          const td = tasks[tasks.length - 1]
          this.lastDisplayedTaskEveryPage.push({
            dueDate: td?.dueDate,
            j: td?.j,
            isDynamic: td?.isDynamic,
          })
        }

        this.dbTasksToSkipPerPage.push(tasksUsedFromDB || offset)
      } catch (error) {
        console.error(error)
      }
      this.paginationLoading = false
    },
    async fetchTasks(reset = false, totalReset = false) {
      this.loading = true
      try {
        if (!this.processesForTasks.length || totalReset) {
          const res = await this.getProcessesForTasks({
            onlyMine: this.$route.name === "my-task-list",
          })

          this.processesForTasks = formatProcessesForTaskGeneration(res.nodes)
        }
        await this.generateTasks(reset)
      } catch (err) {
        console.error(err)
      }

      this.loading = false

      return
    },
    resolveTaskStatusVariant(status) {
      if (status === "NotStarted") return "secondary"
      if (status === "Complete") return "success"
      if (status === "InProgress") return "info"
      if (status === "Exported") return "warning"
      return "primary"
    },
    editRecord(activeRecord, idx) {
      if (activeRecord) {
        this.activeRecord = removeExtraFieldsFromTask(activeRecord)
        this.activeRecordIdx = idx
      }
      this.isAddNewTaskSidebarActive = true
      this.isAddNewTaskSidebarInDisplayMode = false
      this.isEditing = true
    },
    displayRecord(activeRecord, idx) {
      this.editRecord(activeRecord, idx)
      this.isAddNewTaskSidebarInDisplayMode = true
      this.isEditing = false
    },
    deleteOrRestoreRecord(activeRecord, idx) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to ${
            activeRecord.deleted ? "restore" : "delete"
          } this task?`,
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: activeRecord.deleted ? "info" : "danger",
            okTitle: activeRecord.deleted ? "Yes" : "Delete",
            cancelTitle: activeRecord.deleted ? "No" : "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirmation) => {
          if (!confirmation) return

          this.updateTask(activeRecord, idx, { deleted: !activeRecord.deleted })
        })
    },
    async updateTask(activeRecord, idx, updatedFields) {
      this.isEditing = true

      try {
        const activeRecordSanitized = removeExtraFieldsFromTask({
          ...activeRecord,
          ...updatedFields,
        })

        const res = await this.$store.dispatch(
          `task/${
            activeRecordSanitized.id ? "updateTask" : "updateFutureTask"
          }`,
          activeRecordSanitized
        )

        const newOrUpdatedTask =
          res.data[activeRecordSanitized.id ? "updateTask" : "updateFutureTask"]

        this.postTaskUpdateActions(activeRecord, newOrUpdatedTask, idx)
      } catch (error) {
        console.error(error)
      }

      this.resetIsEditingValue()
    },
    postTaskUpdateActions(oldTask, newTask, idx) {
      const task = formatPersistedTask({ ...oldTask, ...newTask })
      this.updateLocalTaskList(idx, task)

      if (task?.status === "Complete" || task.deleted) {
        this.generateTasks(false, false, [idx])
      }

      if (idx === null) {
        this.generateTasks(true)
      }
    },
    formatDate,
    formatDateForUser,
    incrementIteration() {
      this.iteration += 1
    },
    loadTwoMoreWeeksClicked() {
      let lastInterval
      if (this.loadTasksDays === 5) {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth(),
          this.to.getDate() + (this.loadTasksDays || 5)
        )
      } else if (this.loadTasksDays === 14) {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth(),
          this.to.getDate() + (this.loadTasksDays || 14)
        )
      } else if (this.loadTasksDays === 30) {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth() + 1,
          this.to.getDate()
        )
      } else if (this.loadTasksDays === 60) {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth() + 2,
          this.to.getDate()
        )
      } else if (this.loadTasksDays === 180) {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth() + 6,
          this.to.getDate()
        )
      } else if (this.loadTasksDays === 365) {
        lastInterval = new Date(
          this.to.getFullYear() + 1,
          this.to.getMonth(),
          this.to.getDate()
        )
      } else {
        lastInterval = new Date(
          this.to.getFullYear(),
          this.to.getMonth(),
          this.to.getDate() + (this.loadTasksDays || 14)
        )
      }
      if (this.from)
        this.dateRange = `${formatDate(this.from)} to ${formatDate(
          lastInterval
        )}`
      else this.dateRange = `null to ${formatDate(lastInterval)}`
    },
    async oldestDate() {
      return new Date(Date.now())
    },
    async startDate() {
      const date = await this.oldestDate(this.tasks)
      return date > Date.now() ? this.from : date
    },
    resetIsEditingValue() {
      this.$nextTick(() => {
        this.isEditing = false
      })
    },
    formattedDueDate(date) {
      // This function is used to format the due date displayed in the <b-table>
      const year = parseInt(date.split("-")[0], 10)
      const month = parseInt(date.split("-")[1], 10) - 1
      const day = parseInt(date.split("-")[2].split("T")[0], 10)
      return new Date(year, month, day).toDateString()
    },
    splitCamelCaseWords(text) {
      const result = text.replace(/([A-Z])/g, " $1")
      return result.charAt(0).toUpperCase() + result.slice(1)
    },

    // for multi-select feature
    selectOrUnselectRow($event, record) {
      if ($event) {
        this.selectedItems.push(record)
      } else {
        const recordToDeleteIndex = this.selectedItems.findIndex(
          (e) => e.handle === record.handle
        )
        this.selectedItems.splice(recordToDeleteIndex, 1)
      }
    },
    selectAllRows() {
      this.selectedItems = [...this.tasksToDisplay]
    },
    clearSelected() {
      this.selectedItems = []
    },
    selectOrUnselectAllRows(value) {
      if (value) this.selectAllRows()
      else this.clearSelected()
    },
    deleteSelectedRecords() {
      this.isEditing = true
      const selectedItemsUpdated = []
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        const selectedItemUpdated = {
          ...this.selectedItems[i],
          deleted: true,
        }
        delete selectedItemUpdated.updated_at
        delete selectedItemUpdated.assignee
        delete selectedItemUpdated.process
        selectedItemsUpdated.push(selectedItemUpdated)
      }
      this.$store
        .dispatch("task/updateTasks", selectedItemsUpdated)
        .then(() => {
          this.tasksToDisplay = this.removeUnion(
            this.tasksToDisplay,
            this.selectedItems,
            "id"
          )
          this.resetIsEditingValue()
          this.selectedItems = []
        })
    },
    showPopup() {
      this.$swal.fire({
        title: "Exporting",
        text: "Exporting the selected tasks… just a moment, please.",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
      })
    },
    downloadTabSeparatedSheet(data) {
      // Convert the list of objects to a tab-separated string
      const tabSeparatedHeaderData = [
        `${"assignee_name"}\t${"assignee_id"}\tdescription\tdueDate\thandle\tis_late\tname\tprocessHandle\titem.status\tupdated_at\tprocess_link\n`,
      ]
      const tabSeparatedContentData = data
        .map(
          (item) =>
            `${item.assignee.name}\t${item.assignee.peopleHandle}\t${
              item.description || "\t"
            }\t${item.dueDate}\t${item.handle}\t${item.is_late}\t${
              item.name
            }\t${item.process.processHandle}\t${item.status}\t${
              item.updated_at
            }\t${process.env.VUE_APP_FE_URL}/processes?q=${item.processId}&qd=${
              item.processId
            }&compact=true`
        )
        .join("\n")
      const tabSeparatedData = [
        ...tabSeparatedHeaderData,
        tabSeparatedContentData,
      ]
      // Create a Blob from the string
      const blob = new Blob([tabSeparatedData], { type: "text/plain" })

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create an anchor element for downloading
      const a = document.createElement("a")
      a.href = url
      const currentDate = moment() // Get the current date and time

      // Format the date in the required format
      const formattedDate = currentDate.format("YYYY-MM-DD-HH-mm-ss")
      a.download = `task_data_${formattedDate}.tsv` // Set the desired file name
      // Trigger a click event on the anchor to start the download
      a.click()

      // Clean up by revoking the URL
      URL.revokeObjectURL(url)
      this.$swal.close()
    },
    removeUnion(arr1, arr2, propertyToCompare) {
      const set2 = new Set(arr2.map((item) => item[propertyToCompare]))
      const result = arr1.filter((item) => !set2.has(item[propertyToCompare]))
      return result
    },
    async markSelectedItemsAsChosenStatus(status) {
      this.isEditing = true
      const selectedItemsUpdated = []
      const resolvedSelectedItems = await this.selectedItems
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        const selectedItemUpdated = removeExtraFieldsFromTask({
          ...this.selectedItems[i],
          status,
        })
        // delete selectedItemUpdated.updated_at
        // delete selectedItemUpdated.assignee
        // delete selectedItemUpdated.process
        selectedItemsUpdated.push(selectedItemUpdated)
      }
      if (status === "Exported") {
        this.showPopup()
      }
      this.$store
        .dispatch("task/updateTasks", selectedItemsUpdated)
        .then(() => {
          this.tasksToDisplay = this.removeUnion(
            this.tasksToDisplay,
            this.selectedItems,
            "id"
          )
          this.resetIsEditingValue()
          this.selectedItems = []
        })
        .finally(() => {
          if (status === "Exported") {
            this.downloadTabSeparatedSheet(resolvedSelectedItems)
          }
        })
    },
    onDateSelect(dateRange) {
      this.selectedQueries = [`date=${dateRange}`]
    },
    onClearFilter() {
      if (this.$route.query.d && this.$route.query.d === "org") {
        this.$router.replace({ query: { q: this.$route.query.q } })
        this.showPagination = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-select";

.task-list-table {
  // position: relative;
  // max-height: 75vh;
  overflow-x: auto;
}

// thead {
//   position: sticky !important;
//   top: 0;
// }

td {
  padding: 0.72rem !important;
}

.per-page-selector {
  width: 90px;
}

.action-buttons {
  margin-left: -10px;

  button {
    margin-right: 4px;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-class-task {
  @extend .truncate;
  vertical-align: middle !important;
}

.status-badges-container {
  justify-content: center;
}

.edit-icon {
  button {
    padding: 0.715rem 0.736rem;
  }
}

.my-id-class-task {
  width: 100px;
  max-width: 100px;
  min-width: 100px;

  div {
    @extend .truncate;
    padding: 0px;
  }
}

th.my-id-class-task {
  @extend .my-id-class-task;
  padding-left: 0px;
  // vertical-align: middle !important;
}

.my-name-class-task {
  @extend .truncate;

  min-width: 250px !important;
  max-width: 350px !important;
  vertical-align: middle !important;
}

.table thead th {
  vertical-align: middle !important;
}

.select-edit {
  width: 50px !important;
  max-width: 50px !important;
  padding: 10.8px !important;
}

th.select-edit {
  width: 50px !important;
  max-width: 50px !important;
  padding: 10.8px !important;
}

td.select-edit {
  width: 50px !important;
  max-width: 50px !important;
  padding: 10.8px !important;
}

.task-action-btns {
  white-space: pre-wrap !important;
  text-align: center;
  vertical-align: middle !important;
}

th.task-action-btns {
  /* min-width: 150px;
  max-width: 150px; */
  padding: 10.08px 28px !important;
}

td.task-action-btns {
  /* min-width: 150px;
  max-width: 150px; */
  padding: 0px !important;
  white-space: pre-wrap !important;
  overflow: initial;
}

.my-date-class {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

th.my-date-class {
  vertical-align: middle !important;
  padding-left: 15px;
}
</style>
<style lang="scss" scoped>
@import "../../@core/scss/vue/libs/vue-select";

.logo {
  height: 17px;
  width: 17px;
  padding: 1px;
  margin-right: 10px;
}

.table-responsive {
  overflow-x: visible !important;
}
</style>
