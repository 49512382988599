export default {
  frequencyTypeOptions: [
    { label: 'As Needed', value: 'AsNeeded' },
    { label: 'Scheduled', value: 'Scheduled' },
  ],
  taskStatusOptions: [
    { label: 'Not Started', value: 'NotStarted' },
    { label: 'In Progress', value: 'InProgress' },
    { label: 'Complete', value: 'Complete' },
  ],
}
